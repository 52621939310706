<template>
  <v-dialog v-model="show" max-width="500" persistent>
    <v-card>
      <v-card-text class="pa-8">
        <span class="title font-weight-bold">
          {{ text || "Are you sure you want to delete this item?" }}
        </span>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="show = false"> Cancel </v-btn>
        <v-btn color="secondary" @click="onConfirm"> Yes </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    text: String,
    data: Object,
    extData: Number,
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },

  methods: {
    onConfirm() {
      console.log(this.data);
      this.$emit("onConfirm", this.data);
      this.show = false;
    },
  },
};
</script>
