import { getInvite, sendInvite, acceptInvite } from "@/routes";

export default {
  namespaced: true,
  state: {
    inviteData: {},
  },
  getters: {
    inviteData: (state) => state.inviteData,
  },
  mutations: {
    SET_INVITE_DATA(state, data) {
      state.inviteData = data;
    },
  },
  actions: {
    sendInvite({ commit }, data) {
      return sendInvite(data);
    },
    getInvite({ commit }, id) {
      return getInvite(id);
    },
    acceptInvite({ commit }, { code, data }) {
      return acceptInvite({ code, data });
    },
  },
};
