import { createBug } from "@/routes";

export default {
  namespaced: true,
  state: {
    bug: [],
  },
  getters: {},
  mutations: {},
  actions: {
    createBug({ commit }, data) {
      return createBug(data);
    },
  },
};
