<template>
  <div>
    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.text"
      :color="snackbar.color"
      top
      right
      elevation="5"
      @click.native="redirectNotification(snackbar.url)"
    >
      <template v-slot:action="{ attrs }">
        <v-btn
          color="quaternary"
          text
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>

      <div class="pa-2 d-flex align-center">
        <div class="mr-2">
          <v-avatar :size="40">
            <v-img :src="guest" alt="Avatar"></v-img>
          </v-avatar>
        </div>
        <div>
          <div class="quaternary--text font-weight-bold">
            {{ snackbar.full_name }}
          </div>
          <div class="truncate-3 quaternary--text">{{ snackbar.message }}</div>
        </div>
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import guest from "@/assets/default/guest.jpg";

export default {
  data() {
    return {
      snackbar: {
        show: false,
        message: "",
        full_name: "",
        url: "",
        color: "",
        timeout: 8000, // Adjust timeout as needed
      },
    };
  },
  computed: {
    guest() {
      return guest;
    },
  },
  methods: {
    showSnackbar(message, name, link, color = "success") {
      this.snackbar.message = message;
      this.snackbar.full_name = name;
      this.snackbar.url = link;
      this.snackbar.color = color;
      this.snackbar.show = true;

      setTimeout(() => {
        this.snackbar.show = false;
      }, this.snackbar.timeout);
    },

    redirectNotification(url) {
      // Check if the URL is available before opening in a new tab
      if (url) {
        window.open(url, "_blank");
      }
    },
  },
};
</script>
