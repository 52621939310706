<template>
  <div class="about d-flex justify-center pa-10">
    <v-btn @click="setShowAbout(true)">About</v-btn>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  name: "About",

  data() {
    return {};
  },

  watch: {},

  mounted() {},

  methods: {
    ...mapMutations("modal", ["setShowAbout"]),
  },

  computed: {},
};
</script>

<style lang="scss" scoped>
.about {
  background: white;
  padding: 15px;
}
</style>
