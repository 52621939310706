import toastr from "toastr";

window.toastr = toastr;
toastr.options = {
  newestOnTop: true,
  closeButton: true,
  preventDuplicates: true,
  showEasing: "swing",
  hideEasing: "linear",
  showMethod: "fadeIn",
  hideMethod: "fadeOut",
};
