<template>
  <div class="help-content d-flex">
    <div class="d-flex flex-grow-1">
      <v-row class="py-13 flex-grow-1" no-gutters>
        <v-col cols="10" lg="8" md="8" sm="10" class="w-90 mx-auto">
          <div class="mb-5">
            <v-btn
              color="primary"
              @click.stop="goTo('/dashboard/help')"
              style="color: #fff !important"
              ><v-icon>mdi-arrow-left</v-icon>Back to help page?</v-btn
            >
          </div>
          <h1 class="text-center mb-5">How to retrieve chatbot URL?</h1>

          <h2>1. In your Pinoybot dashboard, go to the settings tab.</h2>

          <img :src="urlHowTo1" class="w-100 mb-2" />

          <h2>
            2. Select the Customize Chatbot drop down and on the right side
            click copy url.
          </h2>

          <img :src="urlHowTo2" class="w-100 mb-2" />

          <h2>
            3. The url leads to the chatbot you have trained with your data. You
            can now share it to people or place it on your social media for
            curious clients!
          </h2>

          <img :src="urlHowTo3" class="w-100 mb-2" />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import urlHowTo1 from "@/assets/help/url-howto-1.png";
import urlHowTo2 from "@/assets/help/url-howto-2.png";
import urlHowTo3 from "@/assets/help/url-howto-3.png";

export default {
  data() {
    return {};
  },

  computed: {
    urlHowTo1() {
      return urlHowTo1;
    },
    urlHowTo2() {
      return urlHowTo2;
    },
    urlHowTo3() {
      return urlHowTo3;
    },
  },

  methods: {
    goTo(route) {
      this.$router.push(route);
    },
  },
};
</script>

<style lang="scss">
.help-content {
  h2 {
    margin-bottom: 5px !important;
    font-size: 16px;
  }
  p,
  ul,
  ol {
    margin-bottom: 15px !important;
  }
}
</style>
