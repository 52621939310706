<template>
  <div>
    <v-dialog
      v-model="show"
      content-class="modal-sample-component"
      :width="activeSample == 'sample_pdf' ? '690' : '650'"
      persistent
    >
      <v-card>
        <v-card :elevation="4" tile>
          <v-card-title class="pa-2 d-flex justify-space-between">
            <span class="title font-weight-bold"
              >Training {{ typeSample }} Samples</span
            >
            <v-btn icon @click="show = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
        </v-card>
        <v-divider></v-divider>
        <v-card-text
          class="pa-0"
          style="overflow-y: auto"
          :style="
            activeSample == 'sample_url'
              ? 'height: 20vh !important;'
              : 'height: 50vh !important;'
          "
        >
          <LoadingComponent v-if="isLoading" overlay />
          <div class="pa-4">
            <v-card flat v-if="activeSample == 'sample_chat'">
              <h4 class="text-center">Simple Query</h4>
              <hr />
              <v-card-text class="pt-0">
                <div class="question-and-answer-type pr-6 my-2 w-100">
                  <div class="d-flex justify-space-between">
                    <h3 class="primary--text">Question</h3>
                  </div>
                  <v-list-item-content
                    class="font-weight-medium question-bubble py-6 px-4"
                    :style="`background:${activeBot.ai_bg_color}; color: ${activeBot.ai_text_color}`"
                  >
                    How do I train with pdf in Pinoybot?
                  </v-list-item-content>
                  <h3 class="secondary--text mt-2">Answer</h3>
                  <v-list-item-content
                    class="font-weight-medium answer-bubble py-6 px-4"
                    :style="`background:${activeBot.user_bg_color}; color: ${activeBot.user_text_color}`"
                  >
                    If you have a pdf with text and wish to train your bot with
                    it, you should go to the Train Pinoybot tab, select which
                    bot to train if you have multiple bots, and open up the
                    train with pdf tab. You can upload your pdf file there and
                    click the train or submit button.
                  </v-list-item-content>
                </div>
              </v-card-text>

              <h4 class="text-center">Services</h4>
              <hr />
              <v-card-text class="pt-0">
                <div class="question-and-answer-type pr-6 my-2 w-100">
                  <div class="d-flex justify-space-between">
                    <h3 class="primary--text">Question</h3>
                  </div>
                  <v-list-item-content
                    class="font-weight-medium question-bubble py-6 px-4"
                    :style="`background:${activeBot.ai_bg_color}; color: ${activeBot.ai_text_color}`"
                  >
                    Services Offered by MyndConsulting
                  </v-list-item-content>
                  <h3 class="secondary--text mt-2">Answer</h3>
                  <v-list-item-content
                    class="font-weight-medium answer-bubble py-6 px-4"
                    :style="`background:${activeBot.user_bg_color}; color: ${activeBot.user_text_color}`"
                  >
                    <p class="mb-2">
                      <span class="font-weight-bold">Service Name: </span>
                      Develop a website
                    </p>
                    <p class="mb-2">
                      <span class="font-weight-bold">Description: </span>
                      Develop a website according to client requirements using
                      laravel and vue.js. You can also discuss specific details
                      regarding the depolyment and maintenance of the
                      application.
                    </p>
                    <p class="mb-2">
                      <span class="font-weight-bold">Price: </span>
                      Range from 15,000 to 50,000 philippine pesos
                    </p>
                  </v-list-item-content>
                </div>
              </v-card-text>

              <h4 class="text-center">Products</h4>
              <hr />
              <v-card-text class="pt-0">
                <div class="question-and-answer-type pr-6 my-2 w-100">
                  <div class="d-flex justify-space-between">
                    <h3 class="primary--text">Question</h3>
                  </div>
                  <v-list-item-content
                    class="font-weight-medium question-bubble py-6 px-4"
                    :style="`background:${activeBot.ai_bg_color}; color: ${activeBot.ai_text_color}`"
                  >
                    Products offered by Samsonite Sari-Sari store
                  </v-list-item-content>
                  <h3 class="secondary--text mt-2">Answer</h3>
                  <v-list-item-content
                    class="font-weight-medium answer-bubble py-6 px-4"
                    :style="`background:${activeBot.user_bg_color}; color: ${activeBot.user_text_color}`"
                  >
                    <div class="mb-2">
                      <p class="mb-2">
                        <span class="font-weight-bold">Product Name: </span>
                        Toothbrush
                      </p>
                      <p class="mb-2">
                        <span class="font-weight-bold">Description: </span>
                        Flexible Charcoal bristles
                      </p>
                      <p class="mb-2">
                        <span class="font-weight-bold">Price: </span>
                        Php. 119.00
                      </p>
                    </div>

                    <div class="mb-2">
                      <p class="mb-2">
                        <span class="font-weight-bold">Product Name: </span>
                        Floss
                      </p>
                      <p class="mb-2">
                        <span class="font-weight-bold">Description: </span>
                        Good for 100 flosses
                      </p>
                      <p class="mb-2">
                        <span class="font-weight-bold">Price: </span>
                        Php. 134.00
                      </p>
                    </div>

                    <div class="mb-2">
                      <p class="mb-2">
                        <span class="font-weight-bold">Product Name: </span>
                        Nail Cutter
                      </p>
                      <p class="mb-2">
                        <span class="font-weight-bold">Description: </span>
                        Large Nail cutters
                      </p>
                      <p class="mb-2">
                        <span class="font-weight-bold">Price: </span>
                        50 Philippine Pesos
                      </p>
                    </div>
                  </v-list-item-content>
                </div>
              </v-card-text>
            </v-card>

            <v-card flat v-else-if="activeSample == 'sample_text'">
              <v-card-text>
                <h4>
                  Copy and paste your text into the box. For example this
                  article from:
                  <a
                    href="https://www.cnnphilippines.com/news/2023/10/10/missing-filipino-found-israel.html"
                    target="_blank"
                    >https://www.cnnphilippines.com/news/2023/10/10/missing-filipino-found-israel.html</a
                  >
                </h4>

                <v-list-item-content
                  class="font-weight-medium"
                  style="font-size: 13px"
                >
                  <p class="pb-2">
                    Metro Manila (CNN Philippines, October 10) — One more
                    missing Filipino in war-torn Israel has been found but six
                    others are still unaccounted for, the Philippine Embassy in
                    Israel reported on Tuesday.
                  </p>
                  <p class="pb-2">
                    “The Embassy confirms that one more of the missing Filipino
                    nationals has been accounted for, a female found in a safe
                    area,” it said in a statement.
                  </p>
                  <p class="pb-2">
                    This reduced the number of missing Filipinos to six — two
                    males and four females.
                  </p>
                  <p class="pb-2">
                    According to the Embassy's latest update, out of the 29
                    Filipinos who were reported missing, 23 have been found or
                    rescued, including one male currently being treated for
                    moderate injuries at a hospital.
                  </p>
                  <p class="pb-2">
                    The reported death of one Filipina, who was earlier listed
                    as missing, is still being verified. “The Embassy’s Labor
                    Attache and Welfare Officer are on the ground to assist the
                    sister of the said Filipina in confirming this with the
                    Israel Police,” it said.
                  </p>
                  <p class="pb-2">
                    Meanwhile, the Embassy recommends that Alert Level 1 be
                    maintained, citing the assessment of the Israeli government,
                    security authorities, and realities on the ground.
                  </p>
                </v-list-item-content>
              </v-card-text>
            </v-card>

            <v-card flat v-else-if="activeSample == 'sample_pdf'">
              <v-card-text>
                <h4>
                  Make sure that the pdf you will be uploading is filled with
                  structured text.
                </h4>
                <h4>
                  Below is an image sample for a pdf file called OFW Watch
                </h4>

                <v-list-item-content class="font-weight-medium">
                  <img :src="pdfSample" />
                </v-list-item-content>
              </v-card-text>
            </v-card>

            <v-card flat v-else-if="activeSample == 'sample_url'">
              <v-card-text>
                <h4>
                  Copy the URL you want data from and paste it into the box.
                  <br />
                  Some URL will not be scraped if it is not available for public
                  scraping.
                </h4>

                <v-list-item-content class="font-weight-bold">
                  <a
                    href="https://www.cnnphilippines.com/news/2023/10/10/missing-filipino-found-israel.html"
                    target="_blank"
                    >https://www.cnnphilippines.com/news/2023/10/10/missing-filipino-found-israel.html</a
                  >
                </v-list-item-content>
              </v-card-text>
            </v-card>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-end">
          <div class="pb-2">
            <v-btn color="primary" @click="show = false">Close</v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import LoadingComponent from "@/components/helpers/Loading.vue";
import pdfSample from "@/assets/samples/pdf.png";

export default {
  name: "modal-about",

  props: {
    text: String,
    activeBot: {
      type: Object,
      required: true,
    },
  },

  components: {
    LoadingComponent,
  },

  data() {
    return {
      isLoading: false,
    };
  },

  methods: {
    ...mapMutations("modal", ["setShowSample", "setActiveSample"]),
  },

  computed: {
    ...mapGetters("modal", ["showSample", "activeSample"]),
    show: {
      get() {
        return this.showSample;
      },
      set(v) {
        this.setShowSample(v);
      },
    },

    typeSample() {
      const activeSample = this.activeSample;

      if (activeSample == "sample_chat") {
        return "Chat";
      } else if (activeSample == "sample_text") {
        return "Text";
      } else if (activeSample == "sample_url") {
        return "URL";
      } else if (activeSample == "sample_pdf") {
        return "PDF";
      }
    },

    pdfSample() {
      return pdfSample;
    },
  },
};
</script>
<style lang="scss" scoped>
.modal-sample-component {
  max-height: 100% !important;
}

.question-bubble,
.answer-bubble {
  white-space: pre-wrap;
  border-radius: 15px;
}
</style>
