import {
  chat,
  deleteUserHistory,
  getConvoHistory,
  getUserHistory,
  sendChat,
  getConversationId,
} from "@/routes";

export default {
  namespaced: true,
  state: {
    chatConvo: {},
    userHistory: [],
  },
  getters: {
    chatConvo: (state) => state.chatConvo,
    userHistory: (state) => state.userHistory,
  },
  mutations: {
    setChatConvo(state, payload) {
      state.chatConvo = payload;
    },
    setUserHistory(state, payload) {
      state.userHistory = payload;
    },
    addUserHistory(state, payload) {
      state.userHistory.unshift(payload);
    },
  },
  actions: {
    chat({}, data) {
      return sendChat(data);
    },
    getConvoHistory({}, data) {
      return getConvoHistory(data);
    },
    getUserHistory({}, data) {
      return getUserHistory(data);
    },
    deleteUserHistory({ commit }, data) {
      return deleteUserHistory(data);
    },
    getConversationId({ commit }, { chatbot_id, params }) {
      return getConversationId({ chatbot_id, params });
    },
  },
};
