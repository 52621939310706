/* icons */
import ImageSelectedIcon from "@/assets/post-selection/images-selected.svg";
import SimpleTextSelectedIcon from "@/assets/post-selection/aa-selected.svg";
import VideoSelectedIcon from "@/assets/post-selection/video-selected.svg";
import LinkSelectedIcon from "@/assets/post-selection/link-selected.svg";
import RichTextSelectedIcon from "@/assets/post-selection/rich-text-selected.svg";
import EmbedSelectedIcon from "@/assets/post-selection/embed.svg";
/* icons end */
import Vue from "vue";
import Store from "@/store";

export default {
  getPostBase() {
    return _.cloneDeep({
      type: "",
      title: "",
      content: "",
      recurrence: [],
      timing: [],
      data: {},
      cover_image: "",
      cover_image_uri: "",
      privacy: "public",
      allow_comment: false,
      image_uri: [],
      images: [],
      is_shareable: false,
      show_alert: false,
    });
  },

  dataSetter(model, type, dataType) {
    model.type = type;
    switch (type) {
      case "attachment":
        switch (dataType) {
          case "image":
            model.activity_name = "Image";
            model.image_uri = [];
            model.images = [];
            model.data = {
              attachment_type: "image",
              list: [],
            };
            return model;

          case "video":
            model.activity_name = "Video";
            model.data = {
              attachment_type: "video",
              video: null,
            };
            return model;

          case "link":
            model.activity_name = "Link";
            model.data = {
              attachment_type: "link",
              url: null,
            };
            return model;
        }
        break;
      case "embed":
        model.data = {
          embed_type: dataType,
          embed: "",
        };
        return model;
      case "podcast":
        model.data = {
          podcast: "",
        };
        return model;
      case "statement":
        model.data = {
          statement_type: dataType,
        };
        return model;
      default:
        model.data = {};
        return model;
    }
  },

  setRrulePostDate(dateSet, time) {
    var parser = new Date(),
      value = moment(dateSet.toISOString()).format("MM/DD/YYYY"),
      parsedTime =
        parser.getHours() +
        ":" +
        parser.getMinutes() +
        ":" +
        parser.getSeconds(),
      date = new Date(value + " " + time ? time : parsedTime);
    date = time
      ? new Date(value + " " + time)
      : new Date(value + " " + parsedTime);
    var offset = parser.getTimezoneOffset(),
      mMoment = moment(date).utcOffset(offset),
      newSetTime = mMoment.utc().toString().substr(16, 8).replace(/(:)/g, ""),
      dtStr =
        moment(mMoment.toString())
          .toISOString()
          .replace(/\W+/g, "")
          .slice(0, 9) +
        newSetTime +
        "Z";

    var rruleDateStr = "DTSTART=" + dtStr + ";UNTIL=" + dtStr + ";FREQ=DAILY";
    return rruleDateStr;
  },

  getCampaignBase() {
    return _.cloneDeep({
      id: "",
      allow_comment: true,
      name: "",
      type: "", //campaign for now
      description: "",
      group_id: null,
      access: "public", //hidden, private, public
      avatar_uri: null,
      cover_image_uri: null,
      brand_default_groups: {
        stats: "",
        feedback: "",
        configuration: "",
        report_concern: "",
        featured_video: "",
      },
      data: {
        campaign_type: "", //string [Bills, Cause, Politician, Brand, Company, Other]
        category: "",
        meet_up_link: "",
        is_published_date: "",
        summary: "",
        social_links: {
          facebook: "",
          twitter: "",
          instagram: "",
          gettr: "",
          gab: "",
          rumble: "",
          website: "",
        },
      },
      is_published: false,
      is_approved: false,
      is_submitted: false,
      contact: {
        name: "",
        email: "",
        street: "",
        city: "",
        country: "",
        zipcode: null,
      },
      start_date: {
        start_date: null,
        termination_date: null,
        // start_date: moment(new Date()).format("YYYY-MM-DD"),
        // termination_date: moment(new Date())
        //   .add(1, "days")
        //   .format("YYYY-MM-DD"),
      },
      // Lacking / temporary data
      billing: {}, //i dont know
      // Lacking / temporary data end
    });
  },

  getGroupBase() {
    return _.cloneDeep({
      id: "",
      submitted_by: "",
      brand_id: "",
      name: "",
      description: null,
      type: "group",
      access: "public",
      members_can_post: true,
      post_require_approval: false,
      is_approved: 0,
      is_featured: false,
      is_published: false,
      data: {},
      avatar_uri: "",
    });
  },

  isIOS() {
    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  },

  getQuillToolbar() {
    return {
      container: [
        [
          {
            size: [
              "8px",
              "10px",
              "11px",
              "12px",
              "13px",
              "14px",
              "18px",
              "24px",
              "30px",
              "36px",
              "48px",
              "60px",
              "72px",
              "96px",
            ],
          },
        ],
        [{ header: ["1", "2", "3", "4", false] }],
        ["bold", "italic", "underline", "strike"],
        [{ align: "" }, { align: "center" }, { align: "right" }],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image", "video-btn"],
        [{ color: [] }, { background: [] }],
      ],
    };
  },

  getPostSelectionTypes() {
    return [
      {
        label: "Simple Text",
        type: "simple_text",
        data_type: "simple_text",
        image: SimpleTextSelectedIcon,
      },
      {
        label: "Rich Text",
        type: "rich_text",
        data_type: "rich_text",
        image: RichTextSelectedIcon,
      },
      {
        label: "Image",
        type: "attachment",
        data_type: "image",
        image: ImageSelectedIcon,
      },
      {
        label: "Video",
        type: "attachment",
        data_type: "video",
        image: VideoSelectedIcon,
      },
      {
        label: "Embed",
        type: "embed",
        data_type: "",
        image: EmbedSelectedIcon,
      },
      {
        label: "Link",
        type: "attachment",
        data_type: "link",
        image: LinkSelectedIcon,
      },
    ];
  },

  scrollTo(data, id) {
    let element = document.getElementById(id ? id : "app");
    if (element) {
      element.scrollTo({ ...{}, ...data });
    }
    // .scrollTo({ ...{ top: 0, behavior: "smooth" }, ...data });
  },

  showMessageDefault(data = {}) {
    return Vue.swal({
      icon: null,
      confirmButtonColor: "#4285f3",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      showConfirmButton: true,
      showCancelButton: true,
      timerProgressBar: false,
      timer: 0,
      toast: false,
      position: "center",
      reverseButtons: true,
      html: null,
      title: null,
      text: null,
      customClass: "default-popup",
      ...data,
    }).then((result) => {
      return Promise.resolve(result);
    });
  },

  convertLinkToHyperlink(str) {
    if (!str) return;
    let pattern = /<a[^>]*>([^<]+)<\/a>/g;

    var newStr = str.replace(
      /(<a href=")?((https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)))(">(.*)<\/a>)?/gi,
      function () {
        var string2 =
          `<a href="${arguments[2]}" target="${
            arguments[2].includes("pinoybot.com") ? "_self" : "_blank"
          }">` +
          (arguments[7] || arguments[2]) +
          "</a>";
        var matches = string2.match(pattern);
        if (matches) {
          return (
            `<a href="${arguments[2]}" target="${
              arguments[2].includes("pinoybot.com") ? "_self" : "_blank"
            }">` +
            (arguments[7] || arguments[2]) +
            "</a>"
          );
        } else {
          return (
            `${arguments[2]}" target="${
              arguments[2].includes("pinoybot.com") ? "_self" : "_blank"
            }">` +
            (arguments[7] || arguments[2]) +
            "</a>"
          );
        }
      }
    );
    return newStr;
  },

  convertEmailToHyperlink(str) {
    if (!str) return;

    var regex = /([a-z0-9._-]+@[a-z0-9.-]+\.[a-z]{2,4})/gi;
    return str.replace(regex, '<a href="mailto:$1">$1</a>');
  },

  goTo(route) {
    Store.commit("ui/setPageLoading", true);
    window.open(route, "_self");
  },
};
