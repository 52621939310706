<template>
  <div class="pinoybot-layout">
    <div>
      <NavigationDrawerMain
        :drawer="showDrawerHistories"
        @drawerUpdated="updateDrawerHistories"
      />
      <NavigationDrawerAdMain
        :drawer="showDrawer"
        @drawerUpdated="updateDrawer"
      />

      <div class="header-section w-100 sticky index-5" style="top: 0">
        <!-- visible only to smaller screen -->
        <HeaderMain
          :drawer="showDrawer"
          class="index-5"
          style="top: 0"
          @showDrawer="updateDrawer"
          @showDrawerHistories="updateDrawerHistories"
          v-if="$vuetify.breakpoint.smAndDown"
        />

        <!-- visible only to larger screen and HOME PAGE-->
        <HeaderAdMain
          :drawer="showDrawer"
          class="index-5"
          style="top: 0"
          v-if="$vuetify.breakpoint.mdAndUp"
        />
      </div>
      <div class="relative">
        <router-view
          class="index-4"
          style="min-height: calc(100vh - 56px)"
          :style="{
            padding: $vuetify.breakpoint.mdAndDown ? '0px' : '0px',
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import HeaderMain from "@/components/template/HeaderMain";
import HeaderAdMain from "@/components/template/home/HeaderAdMain";
import NavigationDrawerMain from "@/components/template/NavigationDrawerMain.vue";
import NavigationDrawerAdMain from "@/components/template/home/NavigationDrawerAdMain.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    HeaderMain,
    HeaderAdMain,
    NavigationDrawerMain,
    NavigationDrawerAdMain,
  },

  data() {
    return {
      showDrawer: false,
      showDrawerHistories: false,
    };
  },

  computed: {
    ...mapGetters("auth", ["currentUser"]),
  },

  methods: {
    updateDrawer(val) {
      this.showDrawer = val;
    },
    updateDrawerHistories(val) {
      this.showDrawerHistories = val;
    },
  },
};
</script>

<style lang="scss"></style>
