import {
  getNotifications,
  getNotificationsNextPage,
  getUnreadNotifications,
  readAllNotification,
  readUserNotification,
  webpushSubscribe,
  webpushUnsubscribe,
  hasWebPushSubscription,
} from "@/routes";
import Vue from "vue";

export default {
  namespaced: true,
  state: {
    notifications: {
      data: [],
    },
    unreadNotifications: {
      data: [],
      next_page_url: null,
    },
  },
  getters: {
    notifications: (state) => state.notifications,
    unreadNotifications: (state) => state.unreadNotifications,
    notificationsNextLink: (state) => state.notifications.data.next_page_url,
  },
  mutations: {
    setNotifications(state, payload) {
      state.notifications.data = payload;
    },
    setUnreadNotifications(state, payload) {
      state.unreadNotifications.data = payload;
    },
    addNextNotification(state, data) {
      Vue.set(state.notifications.data, "next_page_url", data.next_page_url);
      Vue.set(
        state.notifications.data,
        "data",
        state.notifications.data.data.concat(data.data)
      );
    },
    decUnreadNotifications(state, payload) {
      state.unreadNotifications.data.total_unread -= 1;
    },
  },
  actions: {
    getNotifications({ commit, state }) {
      return new Promise((resolve, reject) => {
        getNotifications().then(
          (res) => {
            commit("setNotifications", res.data);
            resolve(res.data);
          },
          (err) => reject(err)
        );
      });
    },
    getUnreadNotifications({ commit, state }) {
      return new Promise((resolve, reject) => {
        getUnreadNotifications().then(
          (res) => {
            commit("setUnreadNotifications", res.data);
            resolve(res.data);
          },
          (err) => reject(err)
        );
      });
    },
    getNotificationsNextPage({ commit }, url) {
      return getNotificationsNextPage(url).then((res) => {
        commit("addNextNotification", res.data);
        return Promise.resolve(res.data);
      });
    },
    readUserNotification({}, { item_id, data }) {
      return readUserNotification(item_id, data);
    },
    readAllNotification({}, { item_id, data }) {
      return readAllNotification(item_id, data);
    },
    async webpushSubscribe({}, { data }) {
      
      return webpushSubscribe(data).then((res) => {
        return Promise.resolve(res.data);
      });
    },

    async webpushUnsubscribe() {
      return webpushUnsubscribe().then((res) => {
        return Promise.resolve(res.data);
      });
    },
    
    async hasWebPushSubscription() {
      return hasWebPushSubscription().then((res) => {
        return Promise.resolve(res.data);
      });
    },
  },
};
