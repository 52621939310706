<template>
  <v-app id="app" class="app-main-page" style="overflow-x: hidden">
    <div v-if="isLoading">
      <LoadingComponent class="mt-4" text="Processing..." />
    </div>
    <template v-else>
      <ModalHolder />
      <NotificationAlert ref="alert" />
      <router-view />
      <LoadingComponent v-if="false" />
    </template>
  </v-app>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import auth from "@/helpers/auth";
import LoadingComponent from "@/components/helpers/Loading";
import ModalHolder from "@/components/modals/ModalHolder";
import constants from "@/helpers/constants";
import NotificationAlert from "@/components/helpers/NotificationAlert";
import Pusher from "pusher-js";
import dotenv from 'dotenv';

dotenv.config()

const publicKey = process.env.VUE_APP_VAPID_PUBLIC_KEY;

export default {
  components: {
    LoadingComponent,
    ModalHolder,
    NotificationAlert,
  },

  created() {
    document.title = "PinoyBot";
  },

  data() {
    return {
      isLoading: false,
      pusherData: null,
      snackbarData: [],
    };
  },

  computed: {
    ...mapGetters("auth", ["currentUser"]),
    ...mapGetters("bots", ["bots", "activeBot"]),

    currentRoute() {
      return this.$route.fullPath;
    },
    myOrigin() {
      return window.location.origin;
    },
    isReady() {
      return {
        user: this.currentUser,
        route: this.$route,
      };
    },
    getCurrentRoute() {
      return this.$route.name;
    },
    
  },

  mounted() {
    if (auth.hasToken() && !this.getAuth) {
      this.getCurrentUser();
      this.getRoles().finally(() => {
        console.log("roles load");
      });
    }
    this.logUserDevice();
    if (this.currentUser) {
      this.getUnreadBotNotifications();
      this.getBotNotifications();
      
    }
    this.pusherListen();
    // this.checkAndRequestNotificationPermission(); 

},

  methods: {
    ...mapActions("auth", ["getCurrentUser"]),
    ...mapActions("notifications", [
      "getNotifications",
      "getUnreadNotifications",
    ]),
    ...mapActions("user", ["getRoles", "getDelegatedAccounts"]),
    ...mapMutations("notifications", ["setNotificationCount"]),
    ...mapActions("notifications", ["webpushSubscribe"]),

    async logUserDevice() {
      const _deviceId = await window.generateUUID();
      const _localDeviceId = await localStorage.getItem(
        constants.PINOYCHATGPT_DEVICE_ID
      );
      if (!_localDeviceId) {
        localStorage.setItem(constants.PINOYCHATGPT_DEVICE_ID, _deviceId);
      }
    },

    async getBotNotifications() {
      try {
        await this.getNotifications();
      } catch {
        console.log("Unable to fetch notification2.");
      }
    },

    async getUnreadBotNotifications() {
      try {
        await this.getUnreadNotifications().then(
          (res) => {},
          (err) => {
            console.log({ resxzxfc: err });
          }
        );
      } catch {
        console.log("Unable to fetch notification2.");
      }
    },

    pusherListen() {
      var pusher = new Pusher("aab52b6069e73a92ba7c", {
        cluster: "ap1",
      });

      if (!this.currentUser) return;

      var channel = pusher.subscribe(`${this.currentUser.id}-user-query`);

      channel.bind("user-query", (data) => {
        console.log("notif", data);
        this.$refs["alert"].showSnackbar(
          data.message,
          data.from?.full_name,
          data.conversation_link,
          "white"
        );
      });
    },

    async checkAndRequestNotificationPermission() {
      if (!("Notification" in window)) {
        console.warn("This browser does not support notifications.");
        return;
      }

      const permission = Notification.permission;

      if (permission === "default") {
        console.log("Requesting notification permission...");
        const result = await Notification.requestPermission();
        if (result === "granted") {
          console.log("Notification permission granted.");
          this.subscribeUser();
        } else {
          console.warn("Notification permission denied or ignored.");
        }
      } else if (permission === "granted") {
        console.log("Notification permission already granted.");
        await this.subscribeUser();
      } else {
        console.warn("Notifications are blocked by the user.");
      }
    },

    async subscribeUser() {
      console.log('public_key', publicKey);
      
      if ("serviceWorker" in navigator) {
        try {
          const registration = await navigator.serviceWorker.ready;
          const subscription = await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: this.urlBase64ToUint8Array(publicKey),
          }); 
          console.log("subscription", JSON.stringify(subscription));

          let key = subscription.getKey('p256dh');
          let auth = subscription.getKey('auth');

          const transformedData = {
            endpoint: subscription.endpoint,
            p256dh_key: key ? btoa(String.fromCharCode.apply(null, new Uint8Array(key))) : null,
            auth_key: auth ? btoa(String.fromCharCode.apply(null, new Uint8Array(auth))) : null,
          };

          console.log('transformedData', transformedData);
          
          await this.webpushSubscribe({data: transformedData});

          console.log("Push subscription successful!!!");
        } catch (error) {
          console.error("Error subscribing to push notifications:", error);
        }
      } else {
        console.warn("Service workers are not supported in this browser.");
      }
    },

    urlBase64ToUint8Array(base64String) {
      const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
      const base64 = (base64String + padding)
        .replace(/-/g, "+")
        .replace(/_/g, "/");

      const rawData = atob(base64);
      const outputArray = new Uint8Array(rawData.length);

      for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
      }
      return outputArray;
    },
  },

  watch: {
    isReady(val) {
      if (val.user && val.route) {
        this.getUnreadBotNotifications();
        this.getBotNotifications();
        if (
          val.route.name != "UserPlayShare" &&
          val.route.name != "UserEmbed" &&
          val.route.name != "DashboardPlayground"
        ) {
          this.pusherListen();
        }
      }
    },
  },
};
</script>