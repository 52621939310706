import Vue from "vue";
export default {
  namespaced: true,
  state: {
    isLoading: false,
    pageLoading: false,
  },
  mutations: {
    setLoading(state, payload) {
      Vue.set(state, "isLoading", payload);
    },
    setPageLoading(state, payload) {
      Vue.set(state, "pageLoading", payload);
    },
  },
  getters: {
    isLoading: (state) => state.isLoading,
    pageLoading: (state) => state.pageLoading,
  },
  actions: {},
};
