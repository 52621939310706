<template>
  <div style="z-index: 1" class="loading-overlay">
    <div
      v-if="overlay"
      style="position: absolute; width: 100%; height: 100%; top: 0; left: 0"
    >
      <v-overlay
        :class="overlayLoadingCenter ? 'align-center' : 'align-start'"
        :style="{
          padding: overlayLoadingCenter ? null : '150px',
          paddingTop: paddingTop,
        }"
        color="#fff"
        absolute
        :value="true"
        style="z-index: 1"
      >
        <div class="fw-page-transition-spinner fw-spinner-bounce-delay">
          <div class="fw-spinner-bounce-delay1"></div>
          <div class="fw-spinner-bounce-delay2"></div>
          <div class="fw-spinner-bounce-delay3"></div>
        </div>
      </v-overlay>
    </div>
    <div v-else class="d-flex flex-column align-center">
      <div
        :style="{ paddingTop }"
        class="fw-page-transition-spinner fw-spinner-bounce-delay"
      >
        <div class="fw-spinner-bounce-delay1"></div>
        <div class="fw-spinner-bounce-delay2"></div>
        <div class="fw-spinner-bounce-delay3"></div>
      </div>
    </div>
    <!-- <div
      v-if="overlay"
      style="position: absolute; width: 100%; height: 100%; top: 0; left: 0"
    >
      <v-overlay color="#ddd" absolute :value="true">
        <div class="d-flex flex-column align-center">
          <v-progress-circular
            :size="small ? 30 : xsmall ? 10 : 50"
            :width="small ? 3 : 4"
            indeterminate
            color="black"
          ></v-progress-circular>
          <h3 v-if="!noText" class="mt-2 black--text">Loading...</h3>
        </div>
      </v-overlay>
    </div>
    <div v-else class="d-flex flex-column align-center">
      <v-progress-circular
        :size="large ? 50 : 30"
        :width="4"
        indeterminate
        color="black"
      ></v-progress-circular>
      <h3 v-if="!noText" class="mt-2 black--text">
        {{ text || "Loading..." }}
      </h3>
    </div> -->
  </div>
</template>

<script>
export default {
  props: {
    large: Boolean,
    overlay: Boolean,
    small: Boolean,
    xsmall: Boolean,
    text: String,
    noText: Boolean,
    paddingTop: String,
    overlayLoadingCenter: Boolean,
  },
};
</script>

<style lang="scss">
.loading-overlay {
  .v-overlay__scrim {
    opacity: 0.8 !important;
  }
}
.fw-page-transition-spinner.fw-spinner-bounce-delay .fw-spinner-bounce-delay1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.fw-page-transition-spinner.fw-spinner-bounce-delay .fw-spinner-bounce-delay2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.fw-page-transition-spinner.fw-spinner-bounce-delay > div {
  width: 18px;
  height: 18px;
  background-color: #4285f3;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: fw-spinner-bounce-delay 1.4s infinite ease-in-out both;
  animation: fw-spinner-bounce-delay 1.4s infinite ease-in-out both;
}
.fw-page-transition-spinner.fw-spinner-bounce-delay {
  width: 70px;
  margin: -9px 0 0 -35px;
  text-align: center;
}
.fw-page-transition-spinner {
  position: absolute;
  padding-top: 9px;
  // left: 50%;
  // top: 50%;
  // z-index: 9;
}
.fw-page-transition-spinner.fw-spinner-bounce-delay div {
  margin: 0 2px;
}
@keyframes fw-spinner-bounce-delay {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  80% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}
</style>
