<template>
  <div
    class="profile-main"
    :class="$vuetify.breakpoint.smAndDown ? '' : 'pa-13'"
  >
    <v-row>
      <v-col cols="12" lg="4" md="6">
        <div class="mb-4">
      <v-switch
        v-model="allowNotifications"
        label="Allow Notifications"
        class="mb-4"
        @change="handleNotificationToggle"
      ></v-switch>
    </div>
        <h2 class="mb-4">Your Details</h2>
        <div class="mb-4">
          <v-text-field
            dense
            hide-details
            class="mb-0"
            label="Email"
            outlined
            disabled
            v-model="userTemplate.email"
          ></v-text-field>
        </div>
        <div class="mb-4">
          <v-text-field
            dense
            hide-details
            class="mb-0"
            label="Username"
            outlined
            v-model="userTemplate.username"
          ></v-text-field>
        </div>
        <div class="mb-4">
          <v-text-field
            dense
            hide-details
            class="mb-0"
            label="First Name"
            outlined
            v-model="userTemplate.first_name"
          ></v-text-field>
        </div>
        <div class="mb-4">
          <v-text-field
            dense
            hide-details
            class="mb-0"
            label="Last Name"
            outlined
            v-model="userTemplate.last_name"
          ></v-text-field>
        </div>
        <v-btn
          :disabled="!hasChanges || isSaving"
          :loading="isSaving"
          @click="profileSubmit()"
          class="text-capitalize"
          color="primary"
          :block="$vuetify.breakpoint.smAndDown"
          >Update</v-btn
        >
      </v-col>
    </v-row>

    <v-row class="mt-10">
      <v-col cols="12" lg="4" md="6">
        <h2 class="mb-4">Change Password</h2>
        <div class="">
          <v-text-field
            height="32"
            dense
            v-model="password.oldPassword"
            :append-icon="oldPass ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="required"
            :type="oldPass ? 'text' : 'password'"
            label="Old Password"
            @click:append="oldPass = !oldPass"
            v-on:keyup.enter="keyEnter"
            outlined
          ></v-text-field>
        </div>
        <div class="">
          <v-text-field
            height="32"
            dense
            v-model="password.newPassword"
            :append-icon="newPass ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="required"
            :type="newPass ? 'text' : 'password'"
            label="New Password"
            @click:append="newPass = !newPass"
            v-on:keyup.enter="keyEnter"
            outlined
          ></v-text-field>
        </div>
        <div class="">
          <v-text-field
            height="32"
            dense
            v-model="password.confirmNewPassword"
            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="required"
            :type="showPass ? 'text' : 'password'"
            label="Confirm Password"
            @click:append="showPass = !showPass"
            v-on:keyup.enter="keyEnter"
            outlined
          ></v-text-field>
        </div>
        <v-btn
          :disabled="!isPasswordUpdated || isPasswordSaving"
          :loading="isPasswordSaving"
          @click="changePassword()"
          class="text-capitalize"
          color="primary"
          :block="$vuetify.breakpoint.smAndDown"
          >Submit</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Strings from "@/helpers/strings";
import Utils from "@/helpers/utils";
import { mapActions, mapGetters, mapMutations } from "vuex";
const publicKey = process.env.VUE_APP_VAPID_PUBLIC_KEY;

export default {

  async created() {
    await this.checkNotificationPermission();
  },
  data() {
    return {
      allowNotifications: false,
      showPass: false,
      oldPass: false,
      newPass: false,
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => Strings.validEmail(value),
      },
      userTemplate: null,
      baseUser: null,
      password: {
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
      },
      isSaving: false,
      isPasswordSaving: false,
      required: [(v) => !!v || "Required"],
    };
  },
  watch: {
    currentUser: {
      immediate: true,
      handler(n) {
        if (_.isEmpty(n)) {
          return;
        }
        this.initUserTemplate();
      },
    },
    // allowNotifications(newValue) {
    //   this.handleNotificationToggle(newValue);
    // },
  },
  computed: {
    ...mapGetters("auth", ["currentUser"]),
    hasProfileChanges() {
      return !_.isEqual(this.userTemplate, this.baseUser);
    },
    isPasswordUpdated() {
      return (
        this.password.oldPassword ||
        this.password.newPassword ||
        this.password.confirmNewPassword
      );
    },
    hasChanges() {
      return this.hasProfileChanges;
    },
  },
  methods: {
    ...mapActions("auth", ["updateUser", "changePassword"]),
    ...mapMutations("auth", ["setCurrentUser", "setDefaultUser"]),
    ...mapActions("notifications", ["webpushSubscribe", "webpushUnsubscribe", "hasWebPushSubscription"]),
    initUserTemplate() {
      let userTemplate = _.cloneDeep(this.currentUser);
      this.userTemplate = userTemplate;
      this.baseUser = _.cloneDeep(userTemplate);
    },
    validate() {
      if (
        this.password.newPassword ||
        this.password.oldPassword ||
        this.password.confirmNewPassword
      ) {
        if (!this.password.newPassword) {
          globalErrorHandler("", "New password is required.");
          return false;
        }
        if (!this.password.oldPassword) {
          globalErrorHandler("", "Old password is required.");
          return false;
        }
        if (!this.password.confirmNewPassword) {
          globalErrorHandler("", "Please retype your new password.");
          return false;
        }
        if (this.password.newPassword != this.password.confirmNewPassword) {
          globalErrorHandler(
            "",
            "Your new password and confirmation new password do not match."
          );
          return false;
        }
      }
      return true;
    },
    profileSubmit: _.debounce(function (item) {
      this.updateProfile(this.userTemplate);
    }, 300),

    changePassword: _.debounce(function (item) {
      this.updatePassword(this.userTemplate);
    }, 300),
     // Check notification permission
    async checkNotificationPermission() {
      if ("Notification" in window) {
        // this.allowNotifications = Notification.permission === "granted";

        await this.hasWebPushSubscription()
          .then(
            (res) => {
              console.log('hasWebPushSubscription', res);
              this.allowNotifications = res.result
            },

            (err) => {
              this.allowNotifications = false;
              console.log('hasWebPushSubscription', err);
            }
          );
       
      }
    },

     // Request notification permission
    async requestNotificationPermission() {
      if ("Notification" in window) {
        if (this.allowNotifications) {
          //toggle off

          this.allowNotifications = false;
          this.webpushUnsubscribe();
          globalMessageHandler("Notifications disabled.");
         
        } else {
          // toggle on
          const permission = await Notification.requestPermission();
          this.allowNotifications = permission === "granted"; 
          await this.subscribeUser();

          if (this.allowNotifications) {
            globalMessageHandler("Notifications enabled.");
          } else {
            globalErrorHandler("Notification permission denied.");
          }

        }
      } else {
        globalErrorHandler("This browser does not support notifications.");
      }
    },
    async updateProfile(form) {
      this.isSaving = true;
      try {
        if (this.hasProfileChanges) {
          if (this.userTemplate.username == form.username) {
            var params = {
              first_name: form.first_name,
              last_name: form.last_name,
            };
          } else {
            var params = {
              first_name: form.first_name,
              last_name: form.last_name,
              username: form.username,
            };
          }
          await this.updateUser(params)
            .then(
              (res) => {
                globalMessageHandler("Successfully Updated.");
                this.setCurrentUser(res.data);
                this.setDefaultUser(res.data);
                this.isSaving = false;
              },
              (err) => {
                globalErrorHandler(err, "Error updating user!");
              }
            )
            .finally(() => {
              this.isSaving = false;
            });
          this.initUserTemplate();
        }
      } catch (error) {
        globalErrorHandler(error, "Unable to save profile.");
      } finally {
        this.isSaving = false;
      }
    },
    async updatePassword() {
      if (!this.validate()) return;

      this.isPasswordSaving = true;

      try {
        if (this.isPasswordUpdated) {
          let params = {
            new_password: this.password.newPassword,
            old_password: this.password.oldPassword,
          };

          try {
            await this.updateUser(params);

            this.$nextTick(() => {
              this.password.oldPassword = "";
              this.password.newPassword = "";
              this.password.confirmNewPassword = "";
              this.required = [];
            });
            globalMessageHandler("New password updated.");
          } catch (error) {
            if (_.get(error, "response.status", 0) == 401)
              globalErrorHandler(error, "Incorrect old password");
            else globalErrorHandler(error, "Unable to save password");
            this.isPasswordSaving = false;
            return;
          }
        }
      } catch (error) {
        globalErrorHandler(error, "Unable to save profile.");
      } finally {
        this.isPasswordSaving = false;
      }
    },


    async subscribeUser() {
      console.log('public_key', publicKey);
      
      if ("serviceWorker" in navigator) {
        try {
          const registration = await navigator.serviceWorker.ready;
          const subscription = await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: this.urlBase64ToUint8Array(publicKey),
          }); 
          console.log("subscription", JSON.stringify(subscription));

          let key = subscription.getKey('p256dh');
          let auth = subscription.getKey('auth');

          const transformedData = {
            endpoint: subscription.endpoint,
            p256dh_key: key ? btoa(String.fromCharCode.apply(null, new Uint8Array(key))) : null,
            auth_key: auth ? btoa(String.fromCharCode.apply(null, new Uint8Array(auth))) : null,
          };

          console.log('transformedData', transformedData);
          
          await this.webpushSubscribe({data: transformedData});

          console.log("Push subscription successful!!!");
        } catch (error) {
          console.error("Error subscribing to push notifications:", error);
        }
      } else {
        console.warn("Service workers are not supported in this browser.");
      }
    },

    async handleNotificationToggle(enabled) {

      if (!enabled) {
        globalMessageHandler("To Disable Notifications, Please Disable it on the Browser's Setting.");
        // await this.webpushUnsubscribe();
      } else {
        const permission = await Notification.requestPermission();
        if (permission === "granted") {
          this.allowNotifications = true;
          await this.subscribeUser();
          globalMessageHandler("Notifications enabled.");
      } else {
        await this.webpushUnsubscribe();
        globalErrorHandler("Notification permission denied.");
      }
    }
  },

    urlBase64ToUint8Array(base64String) {
      const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
      const base64 = (base64String + padding)
        .replace(/-/g, "+")
        .replace(/_/g, "/");

      const rawData = atob(base64);
      const outputArray = new Uint8Array(rawData.length);

      for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
      }
      return outputArray;
    },
  },
};
</script>
