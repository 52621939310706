import Vue from "vue";
import vuetify from "@/plugins/vuetify";
import "@/plugins/lodash";
import "@/plugins/axios";
import "@/plugins/font-awesome-vue";
import "@/plugins/moment";
import "@/plugins/toastr";
import "@/plugins/sweetalert2";
import "@/plugins/broadcast";
import "@/plugins/handlers";
import "@/assets/scss/app.scss";
import "./registerServiceWorker";
import "@/plugins/helpers";
import "@/plugins/openAI";
import "@/plugins/clipboard";
import router from "./router";
import store from "./store";
import App from "./App.vue";

Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
