<template>
  <div class="help-content d-flex">
    <div class="d-flex flex-grow-1">
      <v-row class="py-13 flex-grow-1" no-gutters>
        <v-col cols="10" lg="8" md="8" sm="10" class="w-90 mx-auto">
          <div class="mb-5">
            <v-btn
              color="primary"
              @click.stop="goTo('/dashboard/help')"
              style="color: #fff !important"
              ><v-icon>mdi-arrow-left</v-icon>Back to help page?</v-btn
            >
          </div>
          <h1 class="text-center mb-5">
            How to Embed your Chatbot on your site?
          </h1>

          <h2>1. On your Pinoybot dashboard, go to your settings.</h2>

          <img :src="embed1" class="w-100 mb-2" />

          <h2>2. Select the Embed with Web dropdown and click copy code.</h2>

          <img :src="embed2" class="w-100 mb-2" />

          <h3>
            3. Paste the code on your site wherever you’d like it to appear and
            it can communicate with the chatbot you have trained.
          </h3>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import embed1 from "@/assets/help/embed-howto-1.png";
import embed2 from "@/assets/help/embed-howto-2.png";

export default {
  data() {
    return {};
  },

  computed: {
    embed1() {
      return embed1;
    },
    embed2() {
      return embed2;
    },
  },

  methods: {
    goTo(route) {
      this.$router.push(route);
    },
  },
};
</script>

<style lang="scss">
.help-content {
  h2 {
    margin-bottom: 5px !important;
    font-size: 16px;
  }
  p,
  ul,
  ol {
    margin-bottom: 15px !important;
  }
}
</style>
