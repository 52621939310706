export default {
  validEmail(email) {
    if (!email) return "Required.";
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase() || "Invalid e-mail.");
  },

  removeHTMLTags(data) {
    if (!data) return;

    if (data) return data.replace(/<\/?[^>]+(>|$)/g, "");
  },
  getAcronym(str) {
    str = str.replace(/_/g, " ");
    str = str.replace(/-/g, " ");

    var matches = str.match(/\b(\w)/g);
    var acronym = matches.join("");
    return acronym;
  },

  uniqueIdentifierFormat(str) {
    if (!str) return null;

    return str.toLowerCase().replace(/ /g, "-");
  },

  youtubeVideoId(src) {
    var regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = src.match(regExp);
    return match && match[7].length == 11 ? match[7] : null;
  },

  isYoutube(src) {
    var regExp =
      /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = src.match(regExp);
    if (match && match[2].length == 11) {
      return match[2];
    } else {
      return false;
    }
  },

  vimeoId(src) {
    const regExp =
      /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/;
    const parseUrl = regExp.exec(src);
    return parseUrl[5];
  },

  isVimeo(src) {
    var firstPart = src.split("?")[0].split("/");
    return src.includes("vimeo.com") && firstPart[firstPart.length - 1];
  },

  isRumble(src) {
    var firstPart = src.split("?")[0].split("/");
    return src.includes("rumble.com/embed") && firstPart.includes("rumble.com");
  },

  isBitchute(src) {
    return (
      src.includes("bitchute.com/embed") || src.includes("bitchute.com/video")
    );
  },

  isMP4(src) {
    var regExp = "https?.*?.mp4";
    var match = src.match(regExp);
    return match ? match.length > 0 : false;
  },

  isAudio(src) {
    if (!src) return false;

    // let urlSplit = src.split(".");
    // if (urlSplit.length > 1)
    //   return ["mp3", "m4a"].includes(urlSplit[urlSplit.length - 1]);
    // return false;
    var regExp = "https?.*?.mp3";
    var match = src.match(regExp);
    return match ? match.length > 0 : false;
  },

  isPDF(src) {
    let urlSplit = src.split(".");
    if (urlSplit.length > 1)
      return ["pdf"].includes(urlSplit[urlSplit.length - 1]);
    return false;
  },

  isM3u8(src) {
    var regExp = "https?.*?.m3u8";
    var match = src.match(regExp);
    return match ? match.length > 0 : false;
  },

  validURL(str, withoutHttps) {
    var urlRegex = `${
      withoutHttps ? "" : "^(?!mailto:)(?:(?:http|https|ftp)://)"
    }(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$`;
    var url = new RegExp(urlRegex, "i");
    return str.length < 2083 && url.test(str);
  },

  validateImg(url) {
    const regex = /^https?:\/\/.*\/.*\.(png|gif|webp|jpeg|jpg)\??.*$/gim;

    return url.match(regex) != null;
  },

  getAllImage(text) {
    var m,
      urls = [],
      str =
        '<img src="http://site.org/one.jpg />\n <img src="http://site.org/two.jpg />',
      // rex = /<img[^>]+src="?([^"\s]+)"?\s*\/>/g;
      rex = /<img[^>]+src="?([^"\s]+)"?\s*"/g;

    while ((m = rex.exec(text))) {
      urls.push(m[1]);
    }

    return urls;
  },

  formatNumberToPrice(value, fixed = 2) {
    if (!value) value = 0;

    let _value = value.toString().replace(/,/g, "");
    let price;

    if (isNaN(_value) || !_value) {
      return null;
    }

    price = parseFloat(_value).toFixed(fixed);
    price = `${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return price;
  },

  generateLinkContent(inputText) {
    //URLs starting with http://, https://, or ftp://
    var replacePattern1 =
      /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
    var replacedText = inputText.replace(
      replacePattern1,
      '<a class="primary--text" href="$1" target="_blank">$1</a>'
    );
    return replacedText;
  },

  removeCData(str) {
    if (!str) return "";

    return str.replace(/<!\[CDATA\[/g, "").replace(/]]>/g, "");
  },
};
