<template>
  <div>
    <v-dialog
      v-model="show"
      content-class="modal-about-component"
      width="650"
      persistent
    >
      <v-card>
        <v-card :elevation="4" tile>
          <v-card-title class="pa-2 d-flex justify-space-between">
            <span class="title font-weight-bold">About</span>
            <v-btn icon @click="show = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
        </v-card>
        <v-divider></v-divider>
        <v-card-text class="pa-0 relative">
          <LoadingComponent v-if="isLoading" overlay />
          <div>
            <!-- <div class="grey lighten-3 w-100 relative" style="height: 240.75px"> -->
            <div class="my-4 px-4 align-center">about</div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-end">
          <div class="pb-2">
            <v-btn color="secondary" @click="show = false">Okay</v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import LoadingComponent from "@/components/helpers/Loading.vue";

export default {
  name: "modal-about",

  components: {
    LoadingComponent,
  },

  data() {
    return {
      isLoading: false,
    };
  },

  methods: {
    ...mapMutations("modal", ["setShowAbout", "setShowImageSelector"]),
  },

  computed: {
    ...mapGetters("modal", ["showAbout"]),
    show: {
      get() {
        return this.showAbout;
      },
      set(v) {
        this.setShowAbout(v);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.modal-about-component {
  max-height: 100% !important;
}
</style>
