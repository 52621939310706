<template>
  <div class="navigation-drawer-main">
    <v-navigation-drawer
      :value="show"
      left
      absolute
      temporary
      @input="drawerUpdated"
      width="260"
      color="#E4E4E7"
    >
      <div
        class="header-logo pointer text-center mt-2"
        @click="redirect('/home')"
      >
        <img lazy-src="logo" :src="topLogo" />
      </div>

      <div v-if="currentUser">
        <Menu />
      </div>
      <div v-else>
        <v-list dense class="pa-3">
          <v-list-item link key="Signin" to="/sign-in">
            <v-list-item-icon>
              <v-icon>mdi-account-key</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>SIGN IN</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-list dense class="pa-3">
          <v-list-item link key="Signup" to="/signup">
            <v-list-item-icon>
              <v-icon>mdi-account-plus</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>SIGN UP </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import topLogoImage from "@/assets/logos/pinoybot-logo-120.png";
import Menu from "@/components/template/dashboard/Menu";
import { mapGetters } from "vuex";

export default {
  components: {
    Menu,
  },
  props: {
    drawer: Boolean,
  },
  data() {
    return {
      group: null,
      mainDrawer: true,
    };
  },

  methods: {
    drawerUpdated(val) {
      this.$emit("drawerUpdated", val);
    },
  },

  computed: {
    ...mapGetters("auth", ["currentUser"]),
    topLogo() {
      return topLogoImage;
    },
    show: {
      get() {
        return this.drawer;
      },
      set(v) {
        this.drawer = v;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.v-list-item--link {
  color: #27272a !important;
}
.v-list-item--active {
  color: #4285f3 !important;
}
</style>
