let base = process.env.VUE_APP_API;

export default {
  base: base,
  me: `${base}me`,
  register: `${base}register`,
  login: `${base}login`,
  logout: `${base}logout`,
  chatCompletion: `${base}chat/completion`,
  conversationsHistory: `${base}conversations/history`,
  conversations: `${base}conversations`,
  user: `${base}user`,
  users: `${base}users`,
  roles: `${base}roles`,

  //fetch chatbots of user
  userChatbots: `${base}user/chatbots`,

  //playground
  sendChat: `${base}send/chat`,

  //train and settings
  chatbot: `${base}chatbot`,

  //delete train data
  deleteTrain: `${base}remove-data`,
  //update train data
  // note should be the same in remove

  //clear all train data
  clearData: `${base}clear-data`,

  //bugs
  bug: `${base}bug`,

  //notifications
  notifications: `${base}notifications`,
  readNotifications: `${base}read/notification`,

  //contacts
  contacts: `${base}contacts`,

  //remove contacts
  deleteContact: `${base}remove`,

  //invite
  invite: `${base}invite`,
  accept: `${base}accept`,

  //delegated
  delegated: `${base}delegated/accounts`,
  conversation: `${base}conversation`,

  facebook: `${base}facebook`,

  chatbotConversations: `${base}chatbot-conversations`,

  webpush: `${base}webpush`


};
