/* eslint-disable no-console */

import { register } from "register-service-worker";


console.log("base_url", process.env.BASE_URL);
const publicKey = process.env.VUE_APP_VAPID_PUBLIC_KEY;

if (process.env.VUE_APP_DEV_STATUS === "production") {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        "App is being served from cache by a service worker.\n" +
          "For more details, visit https://goo.gl/AFskqB"
      );
    },
    registered(registration) {
      console.log("Service worker has been registered.");

      // checkAndRequestNotificationPermission();
    },
    cached() {
      console.log("Content has been cached for offline use.");
    },
    updatefound() {
      console.log("New content is downloading.");
    },
    updated() {
      console.log("New content is available; please refresh.");
    },
    offline() {
      console.log(
        "No internet connection found. App is running in offline mode."
      );
    },
    error(error) {
      console.error("Error during service worker registration:", error);
    },
  });
}

// async function checkAndRequestNotificationPermission() {
//   if (!("Notification" in window)) {
//     console.warn("This browser does not support notifications.");
//     return;
//   }

//   const permission = Notification.permission;

//   if (permission === "default") {
//     console.log("Requesting notification permission...");
//     try {
//       const result = await Notification.requestPermission();
//       if (result === "granted") {
//         console.log("Notification permission granted.");
//         console.log("permission:", Notification.permission);
//         await subscribeUser(); // Ensure subscription is handled asynchronously
//       } else {
//         console.warn("Notification permission denied or ignored.");
//       }
//     } catch (error) {
//       console.error("Error requesting notification permission:", error);
//     }
//   } else if (permission === "granted") {
//     console.log("Notification permission already granted.");
//     await subscribeUser(); // Ensure subscription is handled asynchronously
//   } else {
//     console.warn("Notifications are blocked by the user.");
//   }
// }

// async function subscribeUser() {
//   console.log("public_key", publicKey);

//   if ("serviceWorker" in navigator) {
//     try {
//       const registration = await navigator.serviceWorker.ready;
//       const subscription = await registration.pushManager.subscribe({
//         userVisibleOnly: true,
//         applicationServerKey: urlBase64ToUint8Array(publicKey),
//       });
//       console.log("subscription", JSON.stringify(subscription));

//       // Send subscription to the server (uncomment and implement this part)
//       // await webpushSubscribe(JSON.stringify(subscription));

//       console.log("Push subscription successful!");
//     } catch (error) {
//       console.error("Error subscribing to push notifications:", error);
//     }
//   } else {
//     console.warn("Service workers are not supported in this browser.");
//   }
// }

// function urlBase64ToUint8Array(base64String) {
//   const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
//   const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");
//   const rawData = window.atob(base64);
//   return Uint8Array.from([...rawData].map((char) => char.charCodeAt(0)));
// }
