<template>
  <div>
    <ModalAbout v-if="showAbout" />
    <ModalTrainingHistory v-if="showTrainingHistory" />
    <ModalCreateBot v-if="showCreateBot" />
    <ModalSample v-if="showSample" :activeBot="activeBot" />
  </div>
</template>

<script>
import ModalAbout from "@/components/modals/ModalAbout";
import ModalTrainingHistory from "@/components/modals/ModalTrainingHistory";
import ModalCreateBot from "@/components/modals/ModalCreateBot";
import ModalSample from "@/components/modals/ModalSample";
import { mapGetters } from "vuex";

export default {
  components: {
    ModalAbout,
    ModalTrainingHistory,
    ModalCreateBot,
    ModalSample,
  },

  computed: {
    ...mapGetters("modal", [
      "showAbout",
      "showTrainingHistory",
      "showCreateBot",
      "showSample",
    ]),

    ...mapGetters("bots", ["bots", "activeBot"]),
  },
};
</script>
