<template>
  <div
    class="notifications-main"
    :class="$vuetify.breakpoint.smAndDown ? '' : 'pa-13'"
  >
    <v-row>
      <v-col cols="12" lg="9" md="6">
        <div class="d-flex align-center mb-4">
          <h2 class="">Notifications</h2>
          <div class="ml-5 d-flex">
            <v-btn
              color="success"
              @click="handleReadAllNotification()"
              x-small
              outlined
            >
              <v-icon dense>mdi-check</v-icon>
              Mark All as Read
            </v-btn>
            <LoadingComponent style="margin-left: 60px" v-if="isRead" />
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row class="mt-2">
      <v-col cols="12" lg="6" md="6">
        <div
          v-for="(notification, index) in notificationsData"
          :key="index"
          class="mb-5"
        >
          <v-card
            @click="
              openNotification(notification), redirectNotification(notification)
            "
          >
            <v-icon
              v-if="notification.is_read === 0"
              class="red-indicator"
              color="red"
              size="10"
            >
              mdi-circle
            </v-icon>

            <span class="bot-indicator font-weight-bold pa-1 rounded">
              <v-avatar :size="20">
                <v-img :src="getIcon(notification)" alt="Avatar"></v-img>
              </v-avatar>
            </span>

            <v-row class="pa-2">
              <v-col cols="1" class="py-0">
                <v-avatar :size="30">
                  <v-img :src="getAvatar(notification)" alt="Avatar"></v-img>
                </v-avatar>
              </v-col>
              <v-col cols="11" class="py-0">
                <div :class="$vuetify.breakpoint.smAndDown ? 'ml-2' : ''">
                  <div class="d-flex">
                    <div
                      :class="{
                        'font-weight-bold': notification.is_read === 0,
                      }"
                    >
                      {{ getName(notification) }}
                    </div>
                    <!-- date and time -->
                    <div
                      class="ml-auto font-weight-medium"
                      style="font-size: 12px"
                    >
                      {{ getHumanReadableFormat(notification) | formattedDate }}
                    </div>
                  </div>

                  <div
                    class="truncate-3"
                    style="font-size: 12px"
                    :class="{ 'font-weight-bold': notification.is_read === 0 }"
                  >
                    {{ notification.content }}
                  </div>
                </div>
                <!-- <v-icon v-if="notification.is_read === 0" color="red" size="15"
                  >mdi-circle</v-icon
                > -->
              </v-col>
            </v-row>
          </v-card>
        </div>

        <div class="d-flex justify-center pt-3">
          <p
            align="center"
            v-if="nextPage && !loaded"
            class="feedback-more mr-1"
          >
            <v-btn
              outlined
              color="primary"
              :loading="isLoadMoreLoading"
              small
              @click="loadMoreNotifications"
            >
              Load More
            </v-btn>
          </p>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import guest from "@/assets/default/guest.jpg";
import Utils from "@/helpers/utils";
import moment from "moment";
import LoadingComponent from "@/components/helpers/Loading";

export default {
  components: {
    LoadingComponent,
  },
  data() {
    return {
      loaded: false,
      isLoadMoreLoading: false,
      isRead: false,
    };
  },
  filters: {
    formattedDate(val) {
      return moment(new Date(val)).fromNow();
    },
  },
  watch: {},
  computed: {
    ...mapGetters("bots", ["activeBot"]),
    ...mapGetters("auth", ["currentUser"]),
    ...mapGetters("notifications", ["notifications", "notificationsNextLink"]),

    guestAvatar() {
      return guest;
    },

    notificationsData() {
      return _.get(this.notifications.data, "data", []);
    },

    nextPage() {
      return this.notifications?.data?.next_page_url;
    },
  },
  methods: {
    ...mapActions("notifications", [
      "getNotifications",
      "getNotificationsNextPage",
      "readUserNotification",
      "readAllNotification",
    ]),
    ...mapMutations("notifications", ["decUnreadNotifications"]),

    getName(item) {
      return item?.from?.full_name;
    },

    initBroadcast() {
      this.isLoadMoreLoading;
    },
    getBotNotifications() {
      this.loaded = true;
      this.getNotifications().then((res) => {
        this.loaded = false;
      }),
        (err) => {};
    },

    loadMoreNotifications() {
      this.isLoadMoreLoading = true;
      this.getNotificationsNextPage(this.notificationsNextLink).finally(() => {
        this.isLoadMoreLoading = false;
      });
    },

    async handleReadAllNotification() {
      Utils.showMessageDefault({
        html: `Are you sure you want to mark all as read?`,
        confirmButtonColor: "#4caf50",
        confirmButtonTextColor: "white",
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
      }).then(async (res) => {
        this.isRead = true;
        if (res.isConfirmed) {
          // Mark all notifications as read
          try {
            for (const notification of this.notificationsData) {
              await this.readAllNotification({ data: { is_read: true } });
              this.updateViewedItems(notification);
            }
            globalMessageHandler("Successfully Marked.");
          } catch (error) {
            console.log("error");
          } finally {
            this.isRead = false;
          }
        }
      });
    },

    async openNotification(notification) {
      //set is_read to true if viewed
      try {
        await this.readUserNotification({
          item_id: notification.id,
          data: { is_read: true },
        });
        this.updateViewedItems(notification);
      } finally {
      }
    },

    updateViewedItems: _.debounce(function (val) {
      let index = _.findIndex(this.notifications.data.data, { id: val.id });

      if (this.notifications.data.data[index].is_read != 1) {
        //autoset state as data is already adjusted in this.readNotificationComment()
        this.notifications.data.data[index].is_read = 1;

        //remove notification is read
        //this.notifications.data.data.splice(index, 1);
        this.decUnreadNotifications(true);
      }
    }, 300),

    getIcon(notification) {
      return notification?.chatbot_data?.icon;
    },

    getAvatar(notification) {
      return notification?.from?.profile_pic || this.guestAvatar;
    },

    redirectNotification(notification) {
      const notificationUrl = notification?.notification_url;
      // Check if the URL is available before opening in a new tab
      if (notificationUrl) {
        window.open(notificationUrl, "_blank");
      }
    },

    getHumanReadableFormat(notification) {
      return (
        notification.notificationaeble?.created_at || notification.created_at
      );
    },
  },

  mounted() {
    this.initBroadcast();
    //this.getBotNotifications();
  },

  watch: {
    // activeBot: {
    //   immediate: true,
    //   handler(v) {
    //     this.getBotNotifications();
    //   },
    // },
  },
};
</script>

<style lang="scss" scoped>
.red-indicator {
  position: absolute;
  top: -10px;
  right: -10px;
  margin: 5px;
}

.bot-indicator {
  position: absolute;
  top: 0px;
  right: -35px;
}
</style>
