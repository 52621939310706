<template>
  <div
    class="
      chat-component
      white
      relative
      d-flex
      fill-height
      flex-1 flex-column
      overflow-hidden
    "
    style="max-width: 100%"
  >
    <main class="relative fill-height w-100 flex-1 flex-column overflow-auto">
      <loading v-if="convoLoading" overlayLoadingCenter overlay />

      <div class="d-flex fill-height flex-column">
        <div class="flex-1 overflow-hidden w-100 mx-auto">
          <div
            class="d-flex flex-column"
            style="overflow-y: auto; overflow-x: hidden; height: 60vh"
          >
            <div>
              <!-- greeting message  -->
              <aiBubble
                :text="activeBot.greeting_message"
                :activeBot="activeBot"
                class="mx-4"
              />
              <!-- end greeting message  -->
              <div v-show="convo.length" class="w-100">
                <template v-for="item in convo">
                  <div :key="item.id" class="">
                    <myBubble
                      v-if="item.role == 'user'"
                      :text="item.content"
                      :activeBot="activeBot"
                      class="mx-4"
                    />
                    <aiBubble
                      v-else-if="item.role == 'assistant'"
                      :text="item.content"
                      :activeBot="activeBot"
                      class="mx-4"
                    />
                  </div>
                </template>
                <div id="anchor"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-shrink-0" style="height: 2rem"></div>
      </div>

      <div class="w-100 pb-5">
        <v-card class="flex-row d-flex mx-auto" style="max-width: 42rem">
          <v-card-text class="py-2">
            <div class="mb-3 subtitle-1 black--text">
              <span class="font-weight-bold" style="color: #4285f3"
                >{{ getUserBotName }}<span v-if="playShare">:</span>
              </span>
              <span v-if="!playShare">: {{ getUserBotGreetings }}</span>
            </div>

            <v-textarea
              v-model="message"
              dense
              auto-grow
              outlined
              hide-details
              :rows="numberOfRows"
              :max-rows="1"
              @input="adjustTextareaHeight"
              placeholder="Send a message."
              @keyup.enter="send(message)"
              color="#828DA6"
            >
              <template v-slot:append>
                <v-progress-circular
                  v-if="loading"
                  size="24"
                  color="info"
                  indeterminate
                ></v-progress-circular>
                <v-btn :disabled="!message" icon v-else small>
                  <v-icon @click.stop="send(message)">mdi-send</v-icon>
                </v-btn>
              </template>
            </v-textarea>
          </v-card-text>
        </v-card>
      </div>
    </main>
  </div>
</template>

<script>
import myBubble from "./myBubble.vue";
import aiBubble from "./aiBubble.vue";
import Utils from "@/helpers/utils";
import { mapGetters, mapMutations, mapActions } from "vuex";
import constants from "@/helpers/constants";
import Loading from "../helpers/Loading.vue";
import topLogoImage from "@/assets/logos/pinoybot-200px.png";
import Pusher from "pusher-js";

export default {
  name: "Chat",

  components: {
    myBubble,
    aiBubble,
    Loading,
  },

  props: {
    activeBot: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      deviceId: null,
      message: "",
      loading: false,
      convoLoading: false,
      convoId: null,
      convo: [
        // { content: "test", role: "user" },
        // { content: "answer", role: "assistant" },
        // { content: "test", role: "user" },
        // { content: "answer", role: "assistant" },
        // {
        //   message: `<p>Here's a simple snippet that replicates a typing effect in Javascript:</p>\n<pre><code class="language-html">&lt;!DOCTYPE html&gt;\n&lt;html&gt;\n&lt;head&gt;\n    &lt;meta charset="UTF-8"&gt;\n    &lt;title&gt;Typing Effect&lt;/title&gt;\n&lt;/head&gt;\n&lt;body&gt;\n    &lt;h1 id="typewriter"&gt;&lt;/h1&gt;\n    &lt;script&gt;\n        const text = "Hello, world!"; // Text to display\n        const speed = 100; // Speed of typing effect in milliseconds\n        let i = 0;\n        function typeWriter() {\n            if (i &lt; text.length) {\n                document.getElementById("typewriter").innerHTML += text.charAt(i);\n                i++;\n                setTimeout(typeWriter, speed);\n            }\n        }\n        typeWriter();\n    &lt;/script&gt;\n&lt;/body&gt;\n&lt;/html&gt;</code></pre>\n<p>This code creates a simple HTML page with an empty <code>h1</code> element and uses Javascript to add a typing effect to it. The <code>text</code> variable stores the text to be displayed, while the <code>speed</code> variable determines the speed of the typing effect in milliseconds. </p>\n<p>The <code>typeWriter()</code> function uses a loop and the <code>setTimeout()</code> method to add each character from the <code>text</code> variable to the <code>h1</code> element at the specified speed. </p>\n<p>You can customize this snippet to suit your needs, such as changing the text, speed, or HTML element used.</p>`,
        //   from: 1,
        // },
      ],
      prevScrollHeight: 0,
      numberOfRows: 1,
    };
  },

  async mounted() {
    await this.setDeviceId();
    if (this.convoIdQuery && !this.convoIdParam) {
      this.convoId = this.convoIdQuery;
      this.getConvo(this.convoIdQuery);
    }
    this.checkScrollheight();
  },

  methods: {
    ...mapActions("chat", [
      "chat",
      "getUserHistory",
      "getConvoHistory",
      "getConversationId",
    ]),
    ...mapMutations("chat", ["addUserHistory"]),
    resetData() {
      this.convoId = null;
      this.convo = [];
      this.message = "";
    },
    scrollToBottom() {
      setTimeout(() => {
        Utils.scrollTo(
          {
            top:
              document.getElementById("chat-messages") &&
              document.getElementById("chat-messages").scrollHeight,
            behavior: "smooth",
          },
          "chat-messages"
        );
      }, 200);
    },
    send(msg) {
      if (!msg || this.loading) return;

      if (!this.deviceId) {
        this.setDeviceId();
      }

      this.loading = true;
      this.convo.push({ content: msg, role: "user" });
      this.convo.push({ content: "", role: "assistant" });
      this.message = "";
      this.scrollToBottom();

      // for demo //
      // this.scrollToBottom();
      // this.typeWriterWords(`answer`);
      // return;
      // for demo end //

      let chatData = {
        botId: this.activeBot?.id,
        prompt: msg,
        device_id: this.deviceId,
      };
      if (this.convoId) {
        chatData.conversation_id = this.convoId;
      } else {
        delete chatData.conversation_id;
      }

      this.chat(chatData).then(
        (res) => {
          if (res.data && res.data?.conversation_id) {
            if (!this.convoIdParam && !this.convoIdQuery) {
              this.$router.replace({
                query: {
                  ...this.$route.query,
                  convoId: res.data.conversation_id,
                },
              });
              this.convoId = res.data.conversation_id;

              this.addUserHistory({
                created_at: new Date(),
                id: this.convoId,
                updated_at: new Date(),
                user_id: this.deviceId,
                title: res.data.title,
              });
            }
            // this.convo.push({ content: "", role: "assistant" });
            this.scrollToBottom();
            // if (res.data.content.length > 100) {
            //   this.typeWriterWords(res.data.content);
            // } else {
            //   this.typeWriter(res.data.content);
            // }

            //streamed here
            // if (this.getCurrentRoute == "UserPlayShare") {
            //   this.pusherListen(res.data?.conversation_id);
            // }
          } else {
            this.convo.pop();
            this.loading = false;
            // globalErrorHandler(
            //   null,
            //   "Something went wrong. Please try again later."
            // );
          }
        },
        (err) => {
          this.convo.pop();
          this.loading = false;
          // globalErrorHandler(
          //   null,
          //   "Something went wrong. Please try again later."
          // );
        }
      );
    },
    pusherListen(conversationId) {
      var pusher = new Pusher("aab52b6069e73a92ba7c", {
        cluster: "ap1",
      });

      // Check if the channel is already subscribed
      if (!this.channel) {
        this.channel = pusher.subscribe(
          `${conversationId}-streamed-chatbot-response`
        );

        // Define the event handler function outside of the bind call
        const eventHandler = (data) => {
          console.log("streamed:::", data);
          this.typeWriterWordsStream(data?.responseContent);
          this.scrollToBottom();
        };

        // Bind the event only once
        this.channel.bind("streamed-chatbot-response", eventHandler);
      }
    },
    typeWriter(message) {
      let i = 0;
      const typeWriterEffect = () => {
        if (i < message.length) {
          setTimeout(() => {
            this.convo[this.convo.length - 1].content += message.charAt(i);
            i++;
            typeWriterEffect();
          }, 60);
        } else if (i >= message.length) {
          this.loading = false;
        }
      };
      typeWriterEffect();
    },
    typeWriterWords(message) {
      if (!message) return;

      let i = 0;

      const words = message.match(/[\w'.,!?]+|\n\n|\n|:/g) || [];
      const typeWriterEffect = () => {
        if (i < words.length) {
          setTimeout(() => {
            // Include the space after each word except the last one
            this.convo[this.convo.length - 1].content += words[i] + " ";
            i++;
            typeWriterEffect();
          }, 60);
        } else if (i >= words.length) {
          this.loading = false;
        }
      };
      typeWriterEffect();
    },

    typeWriterWordsStream(message) {
      if (!message) return;

      setTimeout(() => {
        this.convo[this.convo.length - 1].content += message + "";
        this.loading = false;
      }, 60);
    },

    async setDeviceId() {
      this.deviceId = await localStorage.getItem(
        constants.PINOYCHATGPT_DEVICE_ID
      );
      console.log({ deviceId: this.deviceId });
    },
    getAllIndices(str, substr) {
      let indices = [];
      let i = -1;
      while ((i = str.indexOf(substr, i + 1)) !== -1) {
        indices.push(i);
      }
      return indices;
    },
    replaceHtml(msg) {
      let _indeces = this.getAllIndices(this.sample2, "```");

      let originalString = this.sample2;
      let startString = "```html";
      let endString = "```";

      let startIndex = originalString.indexOf(startString);
      let endIndex = _indeces[1] + endString.length;
      // originalString.indexOf(endString, startIndex);

      let replacementString = this.sample2Formatted2;

      if (startIndex != -1 && endIndex != -1) {
        let newString =
          originalString.substring(0, startIndex) +
          replacementString +
          originalString.substring(endIndex);
        console.log(newString);
        return newString;
      }
      return originalString;
    },
    getConvo(id) {
      this.convoLoading = true;
      this.getConvoHistory({ conversation_id: id })
        .then(
          (res) => {
            if (res.data && res.data.length) {
              this.convo = res.data;
              this.scrollToBottom();
            }
          },
          (err) => {}
        )
        .finally(() => {
          this.convoLoading = false;
        });
    },
    checkScrollheight() {
      const element = document.getElementById("chat-messages");
      const observer = new ResizeObserver((entries) => {
        for (let entry of entries) {
          const elScroll = element.scrollHeight;
          const elHeight = element.clientHeight;
          if (this.convo.length == 2 && elScroll > elHeight) {
            this.scrollToBottom();
          }
        }
      });

      observer.observe(element);
    },
    adjustTextareaHeight() {
      setTimeout(() => {
        const textarea = document.querySelector(".v-textarea textarea");
        textarea.style.height = "auto";
        textarea.style.height = `${textarea.scrollHeight}px`;
        this.numberOfRows = Math.min(
          Math.ceil(textarea.scrollHeight / textarea.clientHeight),
          5
        );
      }, 0);
    },
  },

  computed: {
    sample2() {
      return "test";
      // "Here's a basic example of a typing effect in JavaScript:\n\n```html\n<!DOCTYPE html>\n<html>\n<head>\n\t<title>Typing Effect<\/title>\n\t<style>\n\t\t#text {\n\t\t\tfont-size: 24px;\n\t\t\tfont-family: Arial;\n\t\t}\n\t<\/style>\n<\/head>\n<body>\n\t<p id=\"text\"><\/p>\n\n\t<script>\n\t\t\/\/ Create a variable for the text to be typed\n\t\tvar message = \"Hello, world!\";\n\n\t\t\/\/ Use setInterval to repeatedly add one character to the text\n\t\tvar i = 0;\n\t\tvar timer = setInterval(function() {\n\t\t\tdocument.getElementById(\"text\").innerHTML += message.charAt(i);\n\t\t\ti++;\n\t\t\tif (i == message.length) {\n\t\t\t\tclearInterval(timer);\n\t\t\t}\n\t\t}, 100);\n\t<\/script>\n<\/body>\n<\/html>\n```\n\nIn this example, we create a variable called `message` that holds the text we want to type out. Then, we use setInterval to repeatedly add one character to the text every 100 milliseconds. We use a variable called `i` to keep track of which character to add next, and we use the `charAt()` method to grab the character at that position in the `message` variable. Once we've added all the characters, we use `clearInterval()` to stop the typing animation.\n\nTo replicate this typing effect in your own code, simply copy and paste the code above into your HTML file and update the `message` variable to contain the text you want to type out. You can also adjust the interval timing to change the speed of the typing effect. Additionally, you can style the text by modifying the CSS in the <style> tag."
    },
    sample2Formatted() {
      let a = this.sample2;
      a = a.replace("```html", "");
      a = a.replace("```", "");
      return a;
    },
    sample2Formatted2() {
      console.log({ sample2Formatted: this.sample2Formatted });
      let a = this.sample2.split("```html")[1].split("```")[0];
      const textString = a;
      const div = document.createElement("div");
      const div2 = document.createElement("div");
      div.innerHTML = textString;
      console.dir(div);
      let sample = div.getElementsByTagName("script")[0];
      console.log({ sample });
      return `<pre>${sample.innerText}</pre>`;
    },
    sample2Formatted3() {
      const textString = this.sample2.replace(
        "```html\n<!DOCTYPE html>\n<html>\n<head>\n\t",
        ""
      );
      const div = document.createElement("div");
      const div2 = document.createElement("pre");
      const div22 = document.createElement("pre");
      const div3 = document.createElement("div");
      div.innerHTML = textString;
      div3.innerText = textString;
      let sample = div.getElementsByTagName("script")[0];
      let sample2 = div.getElementsByTagName("meta")[0];
      let sample3 = div.getElementsByTagName("title")[0];
      let sample4 = div.getElementsByTagName("head")[0];
      let sample5 = div.getElementsByTagName("h1")[0];
      const scriptText = _.clone(sample.innerText);
      div2.innerText = scriptText;
      div22.innerText = scriptText;
      sample.before(div2);
      sample.before(div22);
      if (sample) {
        sample.remove();
      }
      if (sample2) {
        sample2.remove();
      }
      if (sample3) {
        sample3.remove();
      }
      if (sample4) {
        sample4.remove();
      }
      if (sample5) {
        sample5.remove();
      }
      return `${div3.outerHTML}`;
    },
    convoIdParam() {
      return this.$route.params.itemId;
    },
    convoIdQuery() {
      return this.$route.query.convoId;
    },
    topLogo() {
      return topLogoImage;
    },
    getCurrentRoute() {
      return this.$route.name;
    },
    getUserBotName() {
      return this.activeBot?.name;
    },
    getUserBotGreetings() {
      if (this.activeBot?.greeting_message != null) {
        return this.activeBot?.greeting_message;
      } else {
        return "Hi! Ask me anything.";
      }
    },
    playShare() {
      return this.$route.name == "UserPlayShare";
    },
    isComponentReady() {
      return {
        activeBot: this.activeBot,
        deviceId: this.deviceId,
      };
    },
  },
  watch: {
    convoIdParam: {
      immediate: true,
      handler(val) {
        if (val) {
          this.convoId = val;
          this.getConvo(val);
        }
      },
    },
    $route: {
      handler(val) {
        if (val.name == "playground" && !this.convoIdQuery) {
          this.resetData();
        }
      },
    },
    activeBot: {
      immediate: true,
      handler() {
        this.resetData();
      },
    },
    isComponentReady: {
      immediate: true,
      handler(val) {
        if (val.activeBot && val.deviceId) {
          this.convoLoading = true;
          this.getConversationId({
            chatbot_id: this.activeBot?.id,
            params: { device_id: this.deviceId },
          })
            .then((res) => {
              if (
                this.getCurrentRoute == "UserPlayShare" ||
                this.getCurrentRoute == "UserEmbed"
              ) {
                this.convoId = _.get(res, "data.conversation_id");
                this.pusherListen(_.get(res, "data.conversation_id"));
              }
            })
            .finally(() => {
              this.convoLoading = false;
            });
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.chat-messages {
  overflow: auto;
}

/* Custom thin scrollbar */
.chat-messages::-webkit-scrollbar {
  width: 6px;
}

.chat-messages::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 3px;
}

.chat-messages::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
.chat-messages * {
  overflow-anchor: none;
}
#anchor {
  overflow-anchor: auto;
  height: 1px;
}

.footer {
  position: absolute;
  bottom: 35px;
  width: 100%;
}
</style>
