<template>
  <div class="history-component white h-100">
    <div class="d-flex justify-center w-100 h-100">
      <div id="chat-history" class="chat-messages w-100">
        <div
          @click.stop="openNewItem"
          class="history-item new-history py-4 px-2 mb-2 d-flex justify-center"
        >
          <div class="d-flex align-center w-100 pointer">
            <span
              class="mdi mdi-message-plus-outline mr-3"
              style="font-size: 20px"
            ></span>
            <span class="item-title">
              <span>New Chat</span>
            </span>
          </div>
        </div>
        {{ loading }}
        <template v-if="loading">
          <div class="history-item py-4 px-2 d-flex justify-center">
            <div class="text-center">
              <v-progress-circular
                size="24"
                color="info"
                indeterminate
              ></v-progress-circular>
            </div>
          </div>
        </template>
        <div id="chat-history-list">
          <template v-for="item in convo">
            <div :key="item.id">
              <historyItem
                @open="openItem(item)"
                :active="isActive(item)"
                :item="item"
              />
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import historyItem from "./HistoryItem.vue";
import Utils from "@/helpers/utils";
import constants from "@/helpers/constants";

export default {
  name: "Chat",

  components: {
    historyItem,
  },

  data() {
    return {
      message: "",
      loading: false,
      deviceId: null,
    };
  },
  async mounted() {
    this.setDeviceId();
  },

  computed: {
    ...mapGetters("chat", ["userHistory"]),
    convo() {
      return this.userHistory;
    },
    currentItem() {
      return (
        _.get(this.$route.params, "itemId") ||
        _.get(this.$route.query, "convoId")
      );
    },
  },

  methods: {
    ...mapActions("chat", ["getUserHistory"]),
    ...mapMutations("chat", ["setUserHistory"]),
    async setDeviceId() {
      this.deviceId = await localStorage.getItem(
        constants.PINOYCHATGPT_DEVICE_ID
      );

      this.$nextTick(() => {
        this.getHistory();
      });
      console.log({ deviceId: this.deviceId });
    },
    getHistory() {
      this.loading = true;
      this.getUserHistory(this.deviceId)
        .then(
          (res) => {
            this.setUserHistory(res.data);
          },
          (err) => {
            // globalErrorHandler(
            //   null,
            //   "Something went wrong. Please try again later."
            // );
          }
        )
        .finally(() => {
          this.loading = false;
        });
    },
    openNewItem() {
      this.$router.push("/playground");
    },
    openItem(item) {
      this.$router.push("/playground/" + item.id);
    },
    isActive(item) {
      return this.currentItem == item.id;
    },
  },
};
</script>

<style lang="scss" scoped>
.new-history {
  color: #4285f3;
  border-bottom: 1px solid #ececec;
  font-weight: bold;
}
.new-history:hover {
  background: #4286f32c;
}
#chat-history {
  border: 1px solid #ececec;
  border-radius: 5px;
}
#chat-history-list {
  overflow-y: auto;
  max-height: calc(100% - 63px);
}
.v-navigation-drawer #chat-history-list {
  max-height: calc(100vh - 85px);
}
</style>
