import { getMe, login, register, logout, updateUser } from "@/routes";
import Cons from "@/helpers/constants";
import VueCookie from "vue-cookie";

export default {
  namespaced: true,
  state: {
    currentUser: null,
    appAvatar: {},
    defaultUser: null,
  },
  getters: {
    currentUser: (state) => state.currentUser,
    defaultUser: (state) => state.defaultUser,
    appAvatars(state) {
      let avatars = [];

      if (state.appAvatar && state.appAvatar.base_url) {
        Object.keys(state.appAvatar.images).forEach((key) => {
          if (key == "male" || key == "female") return;
          state.appAvatar.images[key].forEach((avatar) => {
            avatars.push(state.appAvatar.base_url + key + "/" + avatar);
          });
        });
      }

      return avatars;
    },
  },
  mutations: {
    setCurrentUser(state, data) {
      state.currentUser = data;
    },
    setDefaultUser(state, data) {
      state.defaultUser = data;
    },
    setAppAvatar(state, payload) {
      state.appAvatar = payload;
    },
  },
  actions: {
    // {
    // username: "", //email
    // password: ""
    // }
    login({}, data) {
      return login(data).then(
        (res) => {
          VueCookie.set(Cons.TOKEN_KEY, res.data.access_token, 180);
          return Promise.resolve(res);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
    },
    logout({ dispatch, commit }) {
      return new Promise((resolve, reject) => {
        logout().then(
          (res) => {
            commit("setDefaultUser", null);
            commit("setCurrentUser", null);

            axios.defaults.headers.common["Authorization"] = null;
            VueCookie.delete(Cons.TOKEN_KEY);

            resolve(res);
          },
          (err) => reject(err)
        );
      });
    },

    getCurrentUser({ commit }) {
      return getMe().then(
        (res) => {
          commit("setCurrentUser", res.data);
          commit("setDefaultUser", res.data);
          return Promise.resolve(res);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
    },

    register({}, data) {
      const _data = {
        ...data,
        display_name: `${data.first_name} ${data.last_name}`,
        username: data.username,
        email: data.email,
      };

      return register(_data).then(
        (res) => {
          return Promise.resolve(res);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
    },
    updateUser({}, data) {
      return updateUser(data);
    },
  },
};
