<template>
  <div
    class="playground-main"
    :class="$vuetify.breakpoint.smAndDown ? '' : 'pa-13'"
  >
    <h3
      class="d-block align-center text-center"
      v-if="$vuetify.breakpoint.smAndDown"
    >
      Welcome aboard,
      <span v-if="currentUser" class="text-capitalize">
        {{ getCurrentUserFullName }}!
      </span>
      <v-skeleton-loader
        v-else
        type="list-item-two-line"
        width="400"
      ></v-skeleton-loader>
    </h3>

    <h1 class="d-dlex align-center" v-else>
      Welcome aboard,
      <span v-if="currentUser" class="ml-2 text-capitalize">
        {{ getCurrentUserFullName }}!</span
      >
      <v-skeleton-loader
        v-else
        type="list-item-two-line"
        width="400"
      ></v-skeleton-loader>
    </h1>

    <!-- <p class="font-weight-regular mt-1">
      The playground is a creative space where you can explore the capabilities
      of your AI bots.
    </p> -->

    <!-- Bots -->
    <Bots
      :botHeader="'Select, Test and Play with your bots'"
      @isLoadingBot="isLoadingBot"
      :class="$vuetify.breakpoint.smAndDown ? 'd-none' : ''"
    />

    <!-- Chats -->
    <div class="pt-5">
      <LoadingComponent overlay v-if="isLoading" />
      <ChatComponent />
    </div>

    <!-- Chats -->
    <!-- <v-card
      class="pa-0 d-flex chat-height"
      style="box-shadow: none !important"
      v-if="activeBot"
    >
      <LoadingComponent overlay v-if="isLoading" />
      <ChatComponent class="flex-grow-1" :activeBot="activeBot" v-else />
    </v-card> -->
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import Bots from "@/components/bot_profile/bots.vue";
import ChatComponent from "@/components/chat/Chat.vue";
import LoadingComponent from "@/components/helpers/Loading";

export default {
  data() {
    return {
      isLoading: false,
    };
  },

  components: {
    Bots,
    ChatComponent,
    LoadingComponent,
  },

  // watch: {
  //   activeBot: {
  //     immediate: true,
  //     handler(v) {
  //       this.isLoading = true;
  //       if (this.bots.length > 0) {
  //         this.$router.push("/dashboard/playground/" + this.activeBot?.id);
  //       } else {
  //         this.$router.push("/dashboard/playground");
  //       }
  //       setTimeout(() => {
  //         this.isLoading = false;
  //       }, 500);
  //     },
  //   },
  // },

  methods: {
    ...mapMutations("chat", ["setUserHistory"]),

    isLoadingBot(v) {
      this.isLoading = v;
    },
  },

  computed: {
    ...mapGetters("auth", ["currentUser"]),
    ...mapGetters("bots", ["bots", "activeBot"]),

    getCurrentUserFullName() {
      if (
        !this.currentUser.first_name &&
        !this.currentUser.last_name &&
        !this.currentUser.username
      ) {
        return this.currentUser.email;
      } else if (
        !this.currentUser.first_name &&
        !this.currentUser.last_name &&
        !this.currentUser.username
      ) {
        return this.currentUser.email;
      } else if (!this.currentUser.first_name && this.currentUser.last_name) {
        return this.currentUser.username;
      } else if (
        !this.currentUser.first_name &&
        !this.currentUser.last_name &&
        this.currentUser.username
      ) {
        return this.currentUser.username;
      } else {
        return this.currentUser.first_name + " " + this.currentUser.last_name;
      }
    },

    getUserBotName() {
      return this.bots?.name;
    },

    getUserBotIcon() {
      return this.bots?.icon;
    },

    getCurrentRoute() {
      return this.$route.name;
    },
  },
};
</script>

<style lang="scss" scoped>
.about {
  background: white;
  padding: 15px;
}
p,
h1 {
  color: #27272a !important;
}
.chat-height {
  height: calc(63vh) !important;
  max-height: calc(100vh);
}
</style>
