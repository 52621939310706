<template>
  <div>
    <v-list dense class="pa-3">
      <v-list-item
        v-for="item in items"
        :key="item.title"
        :to="item.path"
        @click="
          item.path ? goTo(item.path, item.title) : showCommingSoon(item.path)
        "
        link
        :style="
          totalUnread > 0 && item.title == 'Notifications'
            ? 'padding-top: 13px'
            : ''
        "
      >
        <v-list-item-icon>
          <v-badge
            :content="totalUnread"
            color="red"
            v-if="item.title == 'Notifications' && totalUnread > 0"
          >
            <v-icon>{{ item.icon }}</v-icon>
          </v-badge>

          <v-icon v-else>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title> {{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <div class="px-5">
      <v-divider></v-divider>
    </div>

    <v-list dense class="pa-3">
      <v-list-item link @click.stop="goToHelp()">
        <v-list-item-icon>
          <v-icon>mdi-help-circle</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Help</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <div class="px-5">
      <v-divider></v-divider>
    </div>

    <v-list dense class="pa-3">
      <v-list-item link @click="goToLogout()">
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
//import { getNotifications } from '@/routes';
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      items: [
        { title: "Home", icon: "mdi-home", path: "/home" },
        {
          title: "Playground",
          icon: "mdi-chat",
          path: `/dashboard/playground/`,
        },
        {
          title: "Notifications",
          icon: "mdi-bell",
          path: `/dashboard/notifications/`,
        },
        {
          title: "Manage Bots",
          icon: "mdi-robot",
          path: `/dashboard/manage-bots/`,
        },
        {
          title: "Train Bots",
          icon: "mdi-text-box-outline",
          path: `/dashboard/train-bots/`,
        },
        { title: "History", icon: "mdi-history", path: `/dashboard/history/` },
        // {
        //   title: "Settings",
        //   icon: "mdi-cog",
        //   path: `/dashboard/settings/`,
        // },
        {
          title: "Account",
          icon: "mdi-shield-account",
          path: "",
        },
        {
          title: "Profile",
          icon: "mdi-account-edit",
          path: "/dashboard/profile/",
        },
        {
          title: "Submit a Bug",
          icon: "mdi-bug-check",
          path: "/dashboard/submit-bug",
        },
      ],
    };
  },

  computed: {
    ...mapGetters("auth", ["currentUser"]),
    ...mapGetters("notifications", ["notifications", "unreadNotifications"]),

    totalUnread() {
      return _.get(this.unreadNotifications.data, "total_unread", 0);
    },
  },

  methods: {
    ...mapActions("auth", ["logout"]),

    goTo(route, title) {
      if (route.startsWith("/")) {
        this.$router.push(route);
      }
    },
    goToLogout() {
      this.logout().then(
        () => {
          this.goTo("/home");
        },
        (err) => {
          // globalMessageHandler(
          //   err,
          //   "Something went wrong. Please try again later."
          // );
        }
      );
    },
    goToHelp() {
      // this.goTo("/dashboard/help");
      let url =
        "https://docs.google.com/document/d/16Aki6R6lyRbQ802jIOpkSb6t6driKvaBGvtoHvaWu5k/edit";
      window.open(url, "_blank");
    },
    showCommingSoon(path) {
      if (!path) {
        globalComingSoonHandler(
          "New features and enhancements coming soon!",
          "Coming Soon"
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-list-item--link {
  color: #27272a !important;
}
.v-list-item--active {
  color: #4285f3 !important;
}
</style>
